export default {
  tabs: [
    {
      icon: ["fal", "cubes"],
      title: "Summary Report",
      route: "reports.summary",
    },
    {
      icon: ["fal", "cubes"],
      title: "Detailed Trading Information",
      route: "reports.total-detailed-trading-info",
    },
    {
      icon: ["fal", "cubes"],
      title: "Total Traded Volume",
      route: "reports.total-traded-volume",
    },
    {
      icon: ["fal", "cubes"],
      title: "Detailed Trading Per Account",
      route: "reports.detailed-trading-per-acc",
    },
  ],
};
