<template>
  <b-container fluid class="reports">
    <b-row>
      <b-col cols="auto" class="pl-1 pr-3">
        <b-nav vertical class="w-100">
          <b-nav-item
            v-for="(tab, index) in menu.tabs"
            :key="index"
            :to="{ name: tab.route }"
          >
            <font-awesome-icon :icon="tab.icon" class="mr-75 font-medium-1" />
            <span>
              {{ tab.title }}
            </span>
          </b-nav-item>
        </b-nav>
      </b-col>
      <b-col class="px-0">
        <router-view></router-view>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import menu from "./menu";

export default {
  data() {
    return {
      menu,
    };
  },
};
</script>
<style lang="scss">
.reports {
  .nav-item {
    margin-bottom: 20px;
    padding: 0;
    a {
      padding: 0;
      color: unset;
      &.router-link-active {
        color: var(--primary);
      }
    }
  }
}
</style>
